import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 500.000000 500.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)">
<path d="M2470 3003 c-22 -20 -33 -40 -37 -70 -5 -39 -2 -46 30 -78 47 -47
101 -49 149 -6 28 25 33 36 33 76 0 40 -5 51 -33 76 -44 39 -99 40 -142 2z"/>
<path d="M650 2961 c-171 -32 -276 -114 -330 -257 -33 -88 -38 -84 118 -84
l137 0 25 48 c45 85 161 118 256 71 34 -16 48 -31 66 -69 l23 -49 141 -1 142
0 -10 43 c-30 137 -136 244 -283 284 -63 17 -225 25 -285 14z"/>
<path d="M3175 2953 c-114 -14 -228 -70 -281 -140 -32 -42 -73 -138 -74 -170
0 -23 1 -23 138 -23 l137 1 20 42 c27 56 75 87 136 87 26 0 58 -4 71 -9 35
-13 76 -59 83 -92 l7 -29 135 0 135 0 -7 43 c-34 204 -237 322 -500 290z"/>
<path d="M3800 2785 l0 -165 140 0 140 0 0 165 0 165 -140 0 -140 0 0 -165z"/>
<path d="M4369 2913 c-49 -52 -239 -280 -239 -287 0 -3 73 -6 163 -6 l162 0
135 156 c74 85 137 159 138 165 2 5 -63 9 -160 9 l-164 0 -35 -37z"/>
<path d="M1352 2783 l3 -158 138 -3 137 -3 0 161 0 160 -140 0 -141 0 3 -157z"/>
<path d="M1950 2780 l0 -160 140 0 140 0 0 160 0 160 -140 0 -140 0 0 -160z"/>
<path d="M2394 2777 c-3 -8 -4 -45 -2 -83 l3 -69 138 -3 137 -3 0 86 0 85
-135 0 c-105 0 -137 -3 -141 -13z"/>
<path d="M1830 2490 l0 -92 60 4 c76 5 100 25 100 83 0 65 -24 88 -99 93 l-61
4 0 -92z m98 38 c7 -7 12 -24 12 -38 0 -30 -17 -50 -42 -50 -15 0 -18 8 -18
43 0 24 3 47 7 50 10 11 28 8 41 -5z"/>
<path d="M2247 2498 c-15 -46 -27 -86 -27 -90 0 -16 50 -8 50 7 0 10 11 15 35
15 24 0 35 -5 35 -15 0 -10 10 -15 30 -15 l29 0 -31 90 c-30 87 -32 90 -62 90
-30 0 -32 -3 -59 -82z m73 -9 c0 -10 -7 -19 -15 -19 -15 0 -21 31 -9 43 11 10
24 -3 24 -24z"/>
<path d="M2620 2560 c0 -15 7 -20 25 -20 24 0 25 -2 25 -70 0 -68 1 -70 25
-70 23 0 24 3 27 68 3 65 4 67 31 70 19 2 27 9 27 23 0 17 -8 19 -80 19 -73 0
-80 -2 -80 -20z"/>
<path d="M3032 2491 l-31 -91 25 0 c14 0 27 7 30 15 4 9 19 15 40 15 24 0 34
-5 34 -15 0 -9 9 -15 25 -15 31 0 30 6 -3 100 -25 70 -29 75 -58 78 -32 3 -32
3 -62 -87z m72 13 c10 -26 7 -34 -13 -34 -14 0 -17 6 -13 25 5 28 17 33 26 9z"/>
<path d="M315 2333 c11 -47 68 -134 111 -169 61 -50 139 -81 239 -95 50 -7 95
-18 100 -25 6 -7 34 -40 64 -73 l54 -61 166 0 166 0 -99 99 -98 98 34 23 c78
54 126 114 153 191 l14 39 -143 0 -142 0 -30 -31 c-38 -41 -82 -59 -145 -59
-61 0 -113 23 -142 62 l-21 28 -144 0 -144 0 7 -27z"/>
<path d="M1365 2323 c18 -96 92 -185 191 -229 120 -53 323 -54 459 -3 100 37
186 137 202 234 l6 35 -141 0 -140 0 -21 -35 c-51 -87 -211 -87 -262 0 l-21
35 -140 0 -140 0 7 -37z"/>
<path d="M2392 2218 l3 -143 138 -3 137 -3 0 146 0 145 -141 0 -140 0 3 -142z"/>
<path d="M2825 2338 c18 -61 57 -130 95 -168 148 -148 485 -159 652 -20 36 30
90 126 102 183 l6 27 -135 0 c-101 0 -135 -3 -135 -12 -1 -22 -37 -58 -74 -73
-81 -34 -177 -9 -214 55 l-17 30 -143 0 -143 0 6 -22z"/>
<path d="M3800 2215 l0 -145 140 0 140 0 0 98 0 97 52 47 53 47 -192 1 -193 0
0 -145z"/>
<path d="M4260 2353 c1 -5 36 -69 79 -143 l77 -135 162 -3 c89 -1 162 0 162 3
0 4 -39 69 -86 146 l-86 139 -154 0 c-85 0 -154 -3 -154 -7z"/>
<path d="M2028 1999 c-49 -19 -49 -79 0 -97 15 -6 39 -13 55 -16 15 -4 27 -10
27 -15 0 -17 -28 -19 -55 -5 -35 18 -65 18 -65 0 0 -23 28 -45 65 -52 64 -12
115 15 115 60 0 30 -14 41 -67 55 -23 6 -45 15 -48 20 -8 14 25 22 45 11 45
-24 81 0 45 30 -20 16 -84 22 -117 9z"/>
<path d="M2440 1941 c0 -59 4 -74 24 -98 21 -24 32 -28 76 -28 44 0 55 4 76
28 20 24 24 39 24 98 l0 69 -30 0 -30 0 0 -63 c0 -67 -10 -87 -46 -87 -28 0
-34 17 -34 91 0 59 0 59 -30 59 l-30 0 0 -69z"/>
<path d="M2920 1910 l0 -100 73 0 c49 0 80 5 95 16 25 17 29 56 7 74 -13 11
-13 15 0 33 19 28 19 33 -5 57 -16 16 -33 20 -95 20 l-75 0 0 -100z m120 40
c0 -16 -7 -20 -30 -20 -18 0 -30 5 -30 13 0 20 10 27 37 27 16 0 23 -6 23 -20z
m5 -71 c9 -14 5 -16 -30 -21 -22 -2 -31 1 -33 15 -3 13 3 17 27 17 16 0 33 -5
36 -11z"/>

</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
